import get from 'lodash/get';

import { PREFIX } from './reducer';

export const getDashboard = (state) => get(state, `${PREFIX}.data`, {});

export const getDashboardStatus = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  hasError: get(state, `${PREFIX}.hasError`, false),
});

export const getClientWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.clients`, []),
});
export const getClientDWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.installations`, []),
});
export const getCalificationWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.nps`, false),
});
export const getFilmWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.topFilms`, []),
});
export const getCarWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.topCars`, []),
});
export const getTopInstallersBySales = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.topInstallersBySales`, []),
});
export const getTopInstallersByNps = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.topInstallersByNps`, []),
});
export const getRankingWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.ranking`, {}),
});
export const getPremiumWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.premiumPartner`, ''),
});
export const getClub3mCategoryWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.club3m`, 'Silver'),
});
export const getArqClub3mCategoryWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.club3mArq`, 'Silver'),
});
export const getIngresadosWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.newProjects`, []),
});
export const getProjectStatusWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.projectsByStatus`, []),
});
export const getTopInstallersBySalesArq = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.topInstallersBySales`, []),
});
export const getProjectSheetsWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.pendingFilms`, []),
});
export const getProjectWarrantiesWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.warrantiesByStatus`, []),
});
export const getRegisterProjectsWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: {
    value: get(state, `${PREFIX}.data.building.premiumPartner`, ''),
    total: get(state, `${PREFIX}.data.building.premiumPartnerNeed`, ''),
  },
});
export const getApprovedProjectsWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.meters`, []),
});

export const getFilmByCountryWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.films`, []),
});

export const getAutoInstallersByCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.installers`, []),
});

export const getBuildingInstallersByCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.installers`, []),
});

export const getInstallersByCategory = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.categories`, []),
});

export const getAutoTopNps = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.auto.nps`, []),
});

export const getBuildingProjectsByCountryWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.installations`, []),
});

export const getBuildingProjectsByStatusAndCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.projectsByStatus`, []),
});

export const getBuildingPendingFilmsByCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.pendingFilms`, []),
});

export const getApprovedPendingProjects = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.approvedPendingWarranties`, []),
});

export const getBuildingTopNps = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.nps`, []),
});

export const getBuildingPendingProjects = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.topPendingProjects`, []),
});

export const getBuildingWonProjects = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.topWonProjects`, []),
});

export const getBuildingTopInstallers = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.topInstallers`, []),
});

export const getBuildingTopGlass = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.building.topGlass`, []),
});

//arqfinishes
//installer
export const getArqFinishesNewProjectsWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.newProjects`, []),
});
export const getArqFinishesProjectStatusWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.projectsByStatus`, []),
});
export const getArqFinishesPendingFilmsWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.pendingFilms`, []),
});
export const getArqFinishesWarrantiesByStatusWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.warrantiesByStatus`, []),
});
export const getArqFinishesPremiumPartnerWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: {
    value: get(state, `${PREFIX}.data.glassFinishes.premiumPartner`, ''),
    total: get(state, `${PREFIX}.data.glassFinishes.premiumPartnerNeed`, ''),
  },
});
export const getArqFinishesMetersWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.meters`, []),
});

//arqfinishes
//distributor

export const getTopInstallersBySalesArqFinishes = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.topInstallersBySales`, []),
});

//arqfinishes
//MMM

export const getGFProjectsByCountryWidget = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.installations`, []),
});
export const getGFProjectsByStatusAndCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.projectsByStatus`, []),
});
export const getGFPendingFilmsByCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.pendingFilms`, []),
});
export const getGFApprovedPendingProjects = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(
    state,
    `${PREFIX}.data.glassFinishes.approvedPendingWarranties`,
    [],
  ),
});

export const getGFInstallersByCountry = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.installers`, []),
});
export const getGFTopNps = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.nps`, []),
});
export const getGFPendingProjects = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.topPendingProjects`, []),
});
export const getGFWonProjects = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.topWonProjects`, []),
});
export const getGFTopInstallers = (state) => ({
  isLoading: get(state, `${PREFIX}.isLoading`, false),
  data: get(state, `${PREFIX}.data.glassFinishes.topInstallers`, []),
});
