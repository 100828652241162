import React from 'react';
import Layout from '_metronic/layout/Layout';
import { FormattedMessage } from 'react-intl';

import Typography from '@material-ui/core/Typography';
import RangePicker from './rangePicker';

import Breadcrumbs from 'components/breadcrumb';

import { connect } from 'react-redux';
import { getUserRole } from 'redux/profile/selector';
import { getCountryProfile } from 'redux/profile/selector';

/////////////////////////////AUTOMOVIL///////////////////////////////////////////
import Car from './icons/car';
import AutoWarrantiesWidget from './widgets/automovil/warranties';
import AutoFilmsByCountryWidget from './widgets/automovil/films';
import AutoInstallersByCountryWidget from './widgets/automovil/installers';
import AutoInstallersByCountryMapWidget from './widgets/automovil/installersMap';
import AutoInstallersByCategoryWidget from './widgets/automovil/categories';
import AutoTopNpsWidget from './widgets/automovil/nps';
/////////////////////////////AUTOMOVIL///////////////////////////////////////////
/////////////////////////////ARQUITECTURA////////////////////////////////////////
import Arq from './icons/arq';
import BuildingProjectsWidget from './widgets/arquitectura/warranties';
import BuildingProjectsByStatusWidget from './widgets/arquitectura/status';
import BuildingPendingFilmsWidget from './widgets/arquitectura/films';
import BuildingWarrantiesStatus from './widgets/arquitectura/warrantiesStatus';
import BuildingInstallersByCountryWidget from './widgets/arquitectura/installers';
import BuildingTopNpsWidget from './widgets/arquitectura/nps';
import BuildingTopPendingProjectsWidget from './widgets/arquitectura/pendingProjects';
import BuildingTopWonProjectsWidget from './widgets/arquitectura/wonProjects';
import BuildingTopInstallersWidget from './widgets/arquitectura/topInstallers';
import BuildingFilmProject from './widgets/arquitectura/filmProject';
/////////////////////////////ARQUITECTURA////////////////////////////////////////
/////////////////////////////GLASS FINISHES////////////////////////////////////////
import GF from './icons/arq';
import GFProjectsWidget from './widgets/arqFinishes/warranties';
import GFProjectsByStatusWidget from './widgets/arqFinishes/status';
import GFPendingFilmsWidget from './widgets/arqFinishes/films';
import GFWarrantiesStatus from './widgets/arqFinishes/warrantiesStatus';
import GFInstallersByCountryWidget from './widgets/arqFinishes/installers';
import GFTopNpsWidget from './widgets/arqFinishes/nps';
import GFTopPendingProjectsWidget from './widgets/arqFinishes/pendingProjects';
import GFTopWonProjectsWidget from './widgets/arqFinishes/wonProjects';
import GFTopInstallersWidget from './widgets/arqFinishes/topInstallers';
/////////////////////////////ARQUITECTURA////////////////////////////////////////

const breadcrumb = [{ page: 'dashboard', title: 'Panel principal' }];

const Dashboard = (props) => {
  return (
    <Layout>
      <div className="kt-space-20" />
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-10">
          <div className="kt-space-20" />
          <Breadcrumbs title="Panel principal" items={breadcrumb} />
        </div>
        <div
          className="col-sm-12 col-md-12 col-lg-2"
          style={{ textAlign: 'right' }}
        >
          <div className="kt-space-20" />
          <RangePicker style={{ width: '100%' }} />
        </div>
      </div>

      {Boolean(props.auto) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Automóvil" />
                <Car
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoWarrantiesWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoFilmsByCountryWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoInstallersByCountryWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoInstallersByCountryMapWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoTopNpsWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <AutoInstallersByCategoryWidget />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/* -------------------------------------------------------------- */}

      {Boolean(props.building) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Arquitectura" />
                <Arq
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingProjectsWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingProjectsByStatusWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingPendingFilmsWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingTopPendingProjectsWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingInstallersByCountryWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingTopInstallersWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingTopWonProjectsWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <BuildingFilmProject />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      {/* -------------------------------------------------------------- */}

      {Boolean(props.dinoc || props.fasara) ? (
        <>
          <div className="row">
            <div className="col-12">
              <div className="kt-space-20" />
              <Typography variant="h5">
                <FormattedMessage id="Architectural Finishes" />
                <Arq
                  style={{
                    fontSize: '35px',
                    marginLeft: '10px',
                    top: '-5px',
                    position: 'relative',
                  }}
                />
              </Typography>
              <div className="kt-space-20" />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFProjectsWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFProjectsByStatusWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFPendingFilmsWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFTopPendingProjectsWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFInstallersByCountryWidget />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFTopInstallersWidget />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <GFTopWonProjectsWidget />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Layout>
  );
};
export default connect((state) => {
  return {
    ...getUserRole(state),
    country: getCountryProfile(state),
  };
})(Dashboard);
